import ApplicationAdapter from "./application";
import UrlTemplates from "ember-data-url-templates";

export default ApplicationAdapter.extend(UrlTemplates, {
  urlTemplate: "{+host}/{+namespace}/dashboards",
  queryUrlTemplate: "{+host}/{+namespace}/dashboards{?query*}",
  findRecordUrlTemplate: "{+host}/{+namespace}/dashboards/{id}",
  updateRecordUrlTemplate: "{+host}/{+namespace}/dashboards/{id}",
  deleteRecordUrlTemplate: "{+host}/{+namespace}/dashboards/{id}",
});
