export default {
  // $zpl-error-50
  colors: ["#ff98b9"],
  chart: {
    marginTop: 0,
    marginLeft: 230,
    marginRight: 20,
    marginBottom: 50,
    spacingBottom: 20,
    type: "bar",
    zoomType: "x",
    style: {
      fontFamily: "",
      fontSize: "12px",
    },
  },
  title: {
    text: "",
  },
  plotOptions: {
    series: {
      stacking: "normal",
    },
    bar: {
      states: {
        hover: {
          enabled: false,
        },
      },
      pointWidth: 23,
    },
  },
  xAxis: {
    tickInterval: 1,
    tickLength: 0,
    labels: {
      y: 5,
      useHTML: true,
      step: 1,
      align: "right",
    },
  },
  yAxis: {
    title: {
      y: 10,
      x: -2,
    },
    stackLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontWeight: "normal",
        textOutline: "0px",
      },
      useHTML: true,
    },
    labels: {
      autoRotation: 0,
      style: {
        "font-size": "12px",
        "font-weight": "normal",
      },
    },
  },
  tooltip: {
    enabled: true,
  },
  legend: {
    enabled: false,
  },
};
