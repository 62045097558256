import ApplicationAdapter from "./application";
import UrlTemplates from "ember-data-url-templates";

export default ApplicationAdapter.extend(UrlTemplates, {
  urlTemplate: "{+host}/{+namespace}/email_reports",
  queryUrlTemplate: "{+host}/{+namespace}/email_reports{?query*}",
  findRecordUrlTemplate: "{+host}/{+namespace}/email_reports/{id}",
  updateRecordUrlTemplate: "{+host}/{+namespace}/email_reports/{id}",
  deleteRecordUrlTemplate: "{+host}/{+namespace}/email_reports/{id}",
});
