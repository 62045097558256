export default {
  chart: {
    marginTop: 0,
    marginLeft: 230,
    marginRight: 20,
    marginBottom: 60,
    spacingBottom: 20,
    type: "bar",
    zoomType: "x",
    height: "518px",
  },
  title: {
    text: "",
  },
  plotOptions: {
    series: {
      stacking: "normal",
    },
    bar: {
      states: {
        hover: {
          enabled: false,
        },
      },
      pointWidth: 23,
    },
  },
  xAxis: {
    tickInterval: 1,
    tickLength: 0,
    labels: {
      y: 8,
      useHTML: true,
      step: 1,
      labels: {
        autoRotation: 0,
        style: {
          "font-size": "12px",
          "font-weight": "normal",
        },
      },
    },
  },
  yAxis: {
    title: {
      y: 20,
    },
    stackLabels: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontWeight: "normal",
        textOutline: "0px",
      },
      useHTML: true,
      align: "right",
    },
  },
  tooltip: {
    enabled: true,
  },
  legend: {
    enabled: false,
  },
};
