import DesignEmailController from "zenloop/controllers/listen/survey/funnel/design/email";
import SurveyTranslationsMixin from "zenloop/mixins/survey/translations";
import SurveyMobileSnippetConfig from "zenloop/mixins/survey/mobile-snippet-config";

export default DesignEmailController.extend(
  SurveyTranslationsMixin,
  SurveyMobileSnippetConfig,
  {
    survey: undefined,
  }
);
