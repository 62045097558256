export default {
  chart: {
    zoomType: "x",
    style: {
      fontFamily: "",
      fontSize: "11px",
    },
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
    itemStyle: {
      fontWeight: "600",
      fontSize: "11px",
    },
    symbolWidth: 25,
    reversed: false,
    itemDistance: 0,
    useHTML: true,
  },
  xAxis: {
    tickInterval: 4,
    tickLength: 15,
    tickWidth: 1,
    lineWidth: 1,
    labels: {
      style: {
        "font-size": "11px",
        "font-weight": "normal",
      },
      y: 35,
    },
  },
  yAxis: {
    title: {
      text: "",
    },
    plotLines: [
      {
        width: 1,
      },
    ],
    lineWidth: 1,
    labels: {
      style: {
        "font-size": "11px",
        "font-weight": "normal",
      },
    },
  },
  plotOptions: {
    series: {
      lineWidth: 2,
      marker: {
        radius: 4,
        lineWidth: 2,
        lineColor: null,
        states: {
          hover: {
            radiusPlus: 2,
          },
        },
      },
      states: {
        hover: {
          lineWidthPlus: 0,
        },
      },
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: "normal",
          cursor: "default",
          fontSize: "11px",
          whiteSpace: "nowrap",
        },
        softConnector: true,
      },
    },
    bar: {
      pointWidth: 24,
    },
  },
};
