export default {
  // $zpl-primary-50, $zpl-black-50, $zpl-error-50
  colors: ["#a9e6da", "#86888a", "#ff98b9"],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 480,
        },
        chartOptions: {
          yAxis: {
            tickAmount: 4,
          },
        },
      },
    ],
  },
  chart: {
    marginTop: 0,
    marginLeft: 595,
    marginRight: 20,
    marginBottom: 25,
    spacingLeft: 0,
    spacingBottom: 20,
    type: "bar",
    width: 1156,
    zoomType: "x",
    style: {
      fontFamily: "",
      fontSize: "12px",
    },
  },
  plotOptions: {
    series: {
      stacking: "normal",
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
    bar: {
      states: {
        hover: {
          enabled: false,
        },
      },
      pointWidth: 23,
    },
  },
  xAxis: {
    tickInterval: 1,
    tickLength: 0,
    labels: {
      y: 3,
      useHTML: true,
      style: {
        "font-size": "14px",
        "font-weight": "normal",
      },
    },
  },
  yAxis: {
    title: {
      text: "",
    },
    labels: {
      autoRotation: 0,
      style: {
        "font-size": "12px",
        "font-weight": "normal",
      },
    },
  },
  title: {
    text: "",
  },
  legend: {
    enabled: false,
  },
};
