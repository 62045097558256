import ChannelService from "ember-phoenix-channel/services/channel-service";

import ChannelBaseMixin from "zenloop/mixins/channel/base";
import ChannelAnswersMixin from "zenloop/mixins/channel/answers";
import ChannelNotificationsMixin from "zenloop/mixins/channel/notifications";

export default ChannelService.extend(
  ChannelBaseMixin,
  ChannelAnswersMixin,
  ChannelNotificationsMixin
);
